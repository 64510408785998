<template>
    <div class="filter-container" v-if="showFilters">
        <Input class="text-filter" cont-id="projectFilter" label="Filter Projects" v-model="filter" nolabel clearable />
        <div class="sort-container">
            <h6>Sort By:</h6>
            <Button @click="this.toggleSort('name')" :title="`Sort By: ${this.sort.name==0?'A → Z':''}${this.sort.name>0?'Z→A':''}${this.sort.name<0?'none':''}`">
                Name
                <FeatherIcon v-if="this.sort['name']" :feather-icon="this.sort['name']>0?'arrow-down':'arrow-up'" />
            </Button>
            <!-- <Button @click="this.toggleSort('date')">
                Date
                <FeatherIcon v-if="this.sort['date']" :feather-icon="this.sort['date']>0?'arrow-down':'arrow-up'" />
            </Button> -->
        </div>
    </div>
    <div class="cards-container">
        <TransitionGroup name="slide-fade-up">
            <div v-for="(card) in filtered.slice(0,slice)" :key="card.title" class="card" :class="card.featured&&'featured'">
                <Gallery :images="card.img" :titles="card.imgTitle"></Gallery>
                <div class="card-body">
                    <h4>{{ card.title }}</h4>
                    <p v-html="card.description"></p>
                    <AppLink class="btn" :class="card.featured&&'alt-btn'" :to="`/contact#${card.id}`">I Want This</AppLink>
                </div>
                <Button v-if="card.featured" class="featured-tag wrap alt-btn no-touch">
                    <FeatherIcon feather-icon="star" />
                    Featured
                </Button>
            </div>
        </TransitionGroup>
    </div>
</template>

<script>
    /* eslint-disable */
    import {
        Options,
        Vue
    } from 'vue-class-component'
    import Button from "./Button.vue";
    import Input from "./Input.vue";
    import FeatherIcon from './FeatherIcon.vue';
    import AppLink from './AppLink.vue';
    import PopUp from './PopUp.vue';
    import Gallery from './Gallery.vue';

    @Options({
        name: 'Cards',
        props: {
            collection: Array,
            showFilters: Boolean,
            featured: Boolean,
            slice: Number
        },
        components: {
            Button,
            Input,
            FeatherIcon,
            AppLink,
            PopUp,
            Gallery,

        },
        data() {
            return {
                filter: '',
                sort: {
                    name: 0,
                    date: 0,
                },
                index: null
            }
        },
        computed: {
            filtered() {
                let filterVal = String(this.filter).toLowerCase(),
                    filtered = this.collection;

                filtered = filtered.filter(record => {
                    let keep = 0,
                        desc = record.description.toLowerCase(),
                        titl = record.title.toLowerCase(),
                        feat = record.hasOwnProperty('featured');

                    keep = (titl.indexOf(filterVal) + 1 + desc.indexOf(filterVal) + 1);

                    this.featured && (keep *= feat);

                    return keep > 0;
                })

                if (this.sort.name) {
                    const stripPunc = (t) => t.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()"“”]/g,"");

                    filtered = filtered.sort((a,b) => this.sort.name*stripPunc(a.title).localeCompare(stripPunc(b.title)));
                }

                return filtered
            },
        },
        methods: {
            toggleSort(sortMethod) {
                let s = this.sort[sortMethod];
                // not 0
                if (s) {
                    s - 1
                        // if 1, sets to -1
                        ? s *= 0
                        // if -1, sets to 0
                        : s *= -1;
                // 0, sets to 1
                } else {
                    // sets all sorts to 0
                    Object.keys(this.sort).forEach((i) => {
                        this.sort[i] = 0;
                    });
                    // Starts the sort cycle for the selected sort value
                    s = 1;
                }
                this.sort[sortMethod] = s;
            }
        }
    })

    export default class Card extends Vue {}
</script>

<style lang="scss">
    .filter-container {
        display: flex;
        align-self: stretch;
        gap: 1rem;

        .text-filter {
            flex: 1;
        }
        .sort-container {
            display: flex;
            flex: 1 0;
            gap: 1rem;
            align-items: center;
        }
    }
    .cards-container {
        // display: flex;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
        gap: 1rem;
        // flex-wrap: wrap;
        align-self: stretch;
    }

    .card {
        flex: 1 0 auto;

        position: relative;
        display: flex;
        border-radius: 0.75rem;
        background: var(--Gry7);
        align-items: stretch;

        transition: background .3s, box-shadow .3s;

        &.featured {
            box-shadow: 0 0 0 3px var(--Pur2);
        }

        button.featured-tag {
            font-size: 80%;
            position: absolute;
            inset: clamp(0.25rem, 2vw, .5rem) auto auto clamp(0.25rem, 2vw, .5rem);
            pointer-events: none;

            z-index: 2;

            .icon-cont,
            svg {
                height: 16px;
                min-height: 16px;
                width: 16px;
                min-width: 16px;
                stroke-width: 2px;
            }
        }

        .image-carousel {
            transform: scale(1);
            transition: transform .3s ease;

            z-index: 1;

            &:hover, &:focus, &:focus-within {
                transform: scale(1.07) rotate(-1.5deg);
            }

            & .vueperslide {
                transition: filter .2s;
            }

            & .vueperslide:active {
                filter: brightness(1.33) contrast(.75);
            }
        }

        .card-body {
            flex: 1 auto;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding: clamp(.5rem, 2vw, 1rem);
            gap: .5rem;

            p {
                flex: 1;
            }

            .btn {
                align-self: flex-end;
            }
        }
    }

    #projectFilter {
        width: calc(50% - .5rem);
    }

    @media screen and ($MdBreak) {
        #projectFilter {
            width: 100%;
        }
        .filter-container {
            flex-direction: column;
        }
    }

    @media screen and ($SmBreak) {
        .cards-container {
            display: flex;
            flex-direction: column;
        }

        .card {
            flex-direction: column;

            .card-body {
                flex: 0 1;

                p {
                    flex: 0 1;
                }
            }

            .card-image-container {
                aspect-ratio: 3/2;
            }
        }
    }
</style>