<template>
    <main>
        <section class="stretch-section">
            <h1>Forge Laser Creations & Design</h1>
            <b>Unique and beautiful laser projects.</b>
            <p>Choose from our list of finished projects or submit your own. We can also custom engrave your personal items.</p>
        </section>
        <section class="stretch-section">
            <h2>Recent Projects</h2>
            <h3>Our latest custom projects from clients or prototypes from our workshop.</h3>
            <Cards :collection="this.projects" :slice="2" />
        </section>
        <section class="stretch-section">
            <h2>Featured Products</h2>
            <h3>These projects show off our best skills.</h3>
            <Cards :collection="this.projects" featured/>
            <AppLink to="/projects" class="btn">See All Projects</AppLink>
        </section>
        <section class="stretch-section color-section">
            <h2>Subscribe to Our Mailing List</h2>
            <h3>Get updates on new items, sales, and specials right in your inbox.</h3>
            <form id="subForm"
                name="subscription"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                route="subscribe-thanks">
                <input type="hidden" name="bot-field" class="hidden" />
                <input type="hidden" name="form-name" value="subscription" class="hidden" />
                <Input type="text" value="" name="First" id="sub-first-name" label="First" required />
                <Input type="text" value="" name="Last" id="sub-last-name" label="Last" required />
                <Input type="email" value="" name="Email" id="sub-email" label="Email" required />
                <Button type="submit">Subscribe</Button>
            </form>
        </section>
        <section>
            <h2>Make It Yours</h2>
            <h3>Add custom engravings to your personal items</h3>
            <AppLink to="/contact#custom" class="btn">Contact Us Now</AppLink>
            <small><i>Items must fit within 900mm ⨉ 600mm ⨉ 150mm (35.4" ⨉ 23.6" ⨉ 5.9").<br>Metal items must be evaluated by us for engraving compatibility.</i></small>
        </section>
        <section class="color-section alt-color-section">
            <h2>Technology</h2>
            <div class="cols">
                <div class="col-content">
                    <h3>Aeon MIRA 9</h3>
                    <p>An industrial-grade laser we obtained in 2021. It is our most powerful machine and has a work area of 900mm ⨉ 600mm ⨉ 150mm. The rotary attachment allows us to engrave on tumblers, cups, and thermoses. This machine has greatly broadened the types of projects we can do.</p>
                    <p>Visit <AppLink to="https://aeonlaser.us/mira" newtab>aeonlaser.us</AppLink> for more information.</p>
                </div>
                <div class="col-content">
                    <h3>Glowforge Pro</h3>
                    <p>Our Glowforge machine gives us fundamental laser cutting capabilities without the sacrifices of high cost, frequent maintenance, or technical voodoo. This allows us to put our focus on other things like the creativity and quality of our customers' orders.</p>
                    <p>With special alignment tools for extra precision and large volume jobs, we can provide laser services for just about anyone, no matter how simple or complex the idea.</p>
                    <p>Visit <AppLink to="https://glowforge.com" newtab>glowforge.com</AppLink> for more information.</p>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
    /* eslint-disable */
    import {
        Options,
        Vue
    } from 'vue-class-component'
    import AppLink from '@/components/AppLink.vue'
    import Cards from '@/components/Cards.vue'
    import Button from "@/components/Button.vue"
    import Input from "@/components/Input.vue"
    import $ from 'jquery'

    @Options({
        components: {
            AppLink,
            Cards,
            Button,
            Input
        },
        inject: ['projects'],
        methods: {
            handleSubmit(form) {
                let formData = new FormData(form),
                    postNav = $(form).attr('route');

                fetch('/',{
                        method: "POST",
                        // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        body: new URLSearchParams(formData).toString(),
                    })
                    .then(() => this.$router.push(postNav))
                    .catch((error) => alert(error));
            }
        },
        mounted() {
            const func = (f) => this.handleSubmit(f);
            $('form').on('submit', function(e) {
                e.preventDefault();
                func(this);
            })
        },
    })
    export default class Home extends Vue {}
</script>