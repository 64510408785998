<template>
    <vue-easy-lightbox scrollDisabled loop :visible="visible" :imgs="imgUrls" :index="index" @hide="handleHide"></vue-easy-lightbox>
    <div class="image-carousel">
        <vueper-slides :slide-ratio="1 / 1" :touchable="false" lazy lazy-load-on-drag class="no-shadow">
            <vueper-slide v-for="(image,imgIndex) in images" :key="imgIndex" :image="require(`@/assets/img/${image}-thumb.jpg`)" @click="show(imgIndex)" />
        </vueper-slides>
    </div>
</template>

<script>
    /* eslint-disable */
    import {
        Options,
        Vue
    } from 'vue-class-component'
    import VueEasyLightbox from 'vue-easy-lightbox';
    import {
        VueperSlides,
        VueperSlide
    } from 'vueperslides';
    import 'vueperslides/dist/vueperslides.css';

    @Options({
        name: 'Gallery',
        props: {
            images: Array,
            titles: Array
        },
        data() {
            return {
                index: 0,
                visible: false,
            }
        },
        computed: {
            lgImages() {
                let arr = [];

                for (let i = 0; i < this.images.length; i++) {
                    let obj = {};

                    obj['title'] = this.titles[i];
                    obj['url'] = this.images[i];

                    arr.push(obj);
                }

                console.log(arr);

                return arr;
            },
            imgUrls() {
                let arr = [];
                for (let i of this.images) {
                    let img = require(`@/assets/img/${i}.jpg`);

                    arr.push(img);
                }
                return arr;
            }
        },
        components: {
            VueEasyLightbox,
            VueperSlides,
            VueperSlide,

        },
        methods: {
            show(index) {
                this.index = index
                this.visible = true
            },
            handleHide() {
                this.visible = false
            }
        }
    })

    export default class Gallery extends Vue {}
</script>

<style lang="scss">
    .vueperslides {
        flex: 1 0 200px;
        border-radius: 0.75rem;
        overflow: hidden;

        // So Safari renders the border-radius correctly
        transform: translateZ(0);

        .vueperslides__parallax-wrapper {
            padding: unset !important;
        }

        .vueperslide {
            background-color: center / cover url(../assets/img/blank.gif) ne-repeat;
            cursor: zoom-in;
            background-position: center;
        }
    }

    .vueperslides__bullets .vueperslides__bullet {
        margin: 0 0;
        padding: 10px 6px;

        .default {
            transition: border .3s, outline .3s;
        }

        &:not(.vueperslides__bullet--active) {
            &:hover {
                .default {
                    outline: 0px solid var(--Blu3);
                    border-color: var(--Blu1);
                    border-width: 2px;
                }
            }

            &:focus {
                .default {
                    outline: 3px solid var(--Blu3);
                }
            }

            &:active {
                .default {
                    border-color: var(--Blu2);
                    border-width: 4px
                }
            }
        }
    }

    .vueperslides__arrows .vueperslides__arrow {
        position: absolute;
        padding: 0;
        transform: translateY(-50%);
        width: 2rem;
        height: 200px;
        background-color: transparent;
        backdrop-filter: blur(0);

        transition: backdrop-filter .3s;

        &.vueperslides__arrow--prev {
            border-radius: .75rem 0 0 .75rem;
            left: 0;
        }

        &.vueperslides__arrow--next {
            border-radius: 0 .75rem .75rem 0;
            right: 0;
        }

        svg {
            padding: 0;
            stroke-width: 1.25px;
            height: 1.5rem;
        }

        &:hover,
        &:focus,
        &:focus-visible {
            backdrop-filter: blur(4px) brightness(.875) contrast(.9);

            svg {
                stroke-width: 2.25px;
            }
        }
    }

    .vueperslides__inner,
    .vueperslides__parallax-wrapper {
        height: 100%;
    }

    .image-carousel {
        flex: 0 0 200px;

        position: relative;
        display: flex;
        overflow: hidden;
        flex-wrap: nowrap;
        align-items: stretch;
        aspect-ratio: 1;
    }

    .image-container {
        flex: 1 0 100%;
        display: flex;
        align-items: stretch;

        img {
            // height: 100%;
            object-fit: cover;
        }
    }

    .vel-modal .btn__close {
        display: flex;
        align-items: center;
        &:before {
            content: '(f)';
            font-size: 16px;
        }
    }

    @media screen and ($SmBreak) {
        .vueperslides__bullets .vueperslides__bullet {
            .default {
                width: 24px;
                height: 24px;
                transition: transform .3s ease;
            }

            &.vueperslides__bullet--active {
                .default {
                    background: rgba(var(--BRGB), .66);
                }
            }

            &:not(.vueperslides__bullet--active) {
                .default {
                    background: rgba(var(--WRGB), .66);
                    backdrop-filter: blur(24px);

                    transform: scale(1);

                    &:active {
                        transform: scale(1.33) translateY(-8px);
                    }
                }
            }
        }
    }
</style>