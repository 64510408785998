<template>
    <header>
        <AppLink to="/" class="logo-link" name="Homepage" title="Homepage">
            <SiteLogo id="logoLink" />
        </AppLink>
        <nav>
            <!-- <AppLink to="/products" title="Our Products" class="btn ghost">
                <FeatherIcon feather-icon="grid"></FeatherIcon>
                <span >Products</span>
            </AppLink> -->
            <AppLink to="/projects" title="Our Projects" class="btn ghost">
                <FeatherIcon feather-icon="clipboard" />
                Projects
            </AppLink>
            <AppLink to="/about" title="About Forge Laser Creations & Design" class="btn ghost">
                <FeatherIcon feather-icon="info" />
                About
            </AppLink>
            <!-- <AppLink to="/shopping-bag" class="btn ghost" title="My Shopping Bag">
                <FeatherIcon feather-icon="shopping-bag" />
                Cart
            </AppLink> -->
            <AppLink to="/contact" class="btn ghost" title="Get in touch">
                <FeatherIcon feather-icon="mail" />
                Get in Touch
            </AppLink>
            <AppLink v-if="this.dev" to="/style-guide" class="btn ghost" title="Site Style Guide">
                <FeatherIcon feather-icon="type" />
                Style Guide
            </AppLink>
            <span class="header-divider">&nbsp;|&nbsp;</span>
            <!-- <Button @click="$refs.settingsPopUp.toggle()" class="ghost mobile stay-focused">
                <FeatherIcon feather-icon="sliders" />
                <PopUp ref="settingsPopUp" dir="down">
                    <Button @click.stop="this.$parent.togglePref('darkMode')" class="ghost stay-focused" :title="`Switch to ${this.prefs.darkMode?'Light':'Dark'} Mode`">
                        <FeatherIcon :feather-icon="this.prefs.darkMode?'sun':'moon'" />
                    </Button>
                    <Button @click.stop="this.$parent.togglePref('motion')" class="ghost stay-focused" :title="`Turn Motion & Animations ${this.prefs.motion?'Off':'On'}`">
                        <FeatherIcon :feather-icon="this.prefs.motion?'pause':'play'" />
                    </Button>
                </PopUp>
            </Button> -->
            <Button @click="this.$parent.togglePref('motion')" class="ghost stay-focused" :title="`Turn Motion & Animations ${this.prefs.motion?'Off':'On'}`">
                <FeatherIcon :feather-icon="this.prefs.motion?'pause':'play'" />
            </Button>
            <Button @click="this.$parent.togglePref('darkMode')" class="ghost stay-focused" :title="`Switch to ${this.prefs.darkMode?'Light':'Dark'} Mode`">
                <FeatherIcon :feather-icon="this.prefs.darkMode?'sun':'moon'" />
            </Button>
            <Button v-if="this.dev" @click="this.$parent.cls()" class="ghost">
                <FeatherIcon feather-icon="trash-2" />
            </Button>
        </nav>
    </header>
</template>

<script>
    /* eslint-disable */

    import {
        Options,
        Vue
    } from 'vue-class-component';
    import SiteLogo from './SiteLogo.vue';
    import Button from "./Button.vue";
    import FeatherIcon from './FeatherIcon.vue';
    import AppLink from './AppLink.vue';
    import PopUp from './PopUp.vue'

    @Options({
        name: 'Header',
        components: {
            SiteLogo,
            Button,
            FeatherIcon,
            AppLink,
            PopUp
        },
        inject: ['prefs', 'dev']
    })

    export default class Header extends Vue {}
</script>

<style lang="scss">
    @import '@/styles/_vars.scss';

    header {
        display: flex;
        position: sticky;
        top: 0;
        z-index: 4;
        flex-wrap: wrap;
        gap: 1rem;

        background-color: rgba(var(--WRGB), 0.66);
        backdrop-filter: blur(24px);
        border: 1px solid var(--Gry8);
        border-radius: clamp(.875rem, 2.5vw, 1.75rem);
        padding: clamp(.5rem, 3vw, 1rem) clamp(.5rem, 2.5vw, 1.5rem);
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;

        transition: background-color .3s, top .3s ease, margin .3s ease, border-color .3s, box-shadow .3s, border-radius .3s ease;

        #logoLink {
            border: none !important;
            height: 3.5rem;
            margin: -0.5rem auto;
            filter: drop-shadow(0 0 0 transparent);

            transition: height .3s ease, filter .6s;
        }

        a.logo-link {
            -webkit-user-drag: none;
            transition: transform .25s ease;

            &.router-link-active {
                cursor: default;
                #logoLink {
                    filter: drop-shadow(0 0 12px var(--Blu4));
                }
            }

            &:not(.router-link-active) {
                &:hover,
                &:focus,
                &.focus {
                    transform: scale(1.07);
                }

                &:active,
                &.active {
                    transform: scale(.96);
                }
            }
        }

        nav {
            display: flex;
            gap: clamp(.15rem, .75vw, .33rem);
            align-items: center;
        }

        .header-divider {
            position: relative;
            bottom: 1px;
            color: var(--Gry6);

            user-select: none;
        }
    }

    @media screen and ($MdRBreak) {
        header.snapped {
            top: -1px;
            border-radius: 0 0 clamp(.875rem, 3vw, 1.75rem) clamp(.875rem, 3vw, 1.75rem);
            margin: 0 0 2.66rem;

            #logoLink {
                height: 2.75rem;
            }
        }
    }

    @media screen and ($MdBreak) {
        header {
            position: fixed;
            top: auto;
            bottom: 0;
            margin: 0 0 .5rem;
            align-self: center;
            justify-content: center;
            gap: .75em;
            left: .5rem;
            right: .5rem;

            &.snapped {
                top: auto;
                margin: 0;
                bottom: -1px;
                border-radius: clamp(.875rem, 3vw, 1.75rem) clamp(.875rem, 3vw, 1.75rem) 0 0;
            }

            nav {
                flex-wrap: wrap;
            }
        }
    }
</style>