<template>
    <a v-if="isExternal" :href="to" :target="newtab?'_blank':'_self'">
        <slot />
    </a>
    <router-link v-else v-bind="$props">
        <slot />
    </router-link>
</template>
<script>
    /* eslint-disable */
    import {
        Options,
        Vue
    } from 'vue-class-component'
    import {
        RouterLink
    } from 'vue-router'

    @Options({
        props: {
            ...RouterLink.props,
            newtab: Boolean
        },
        computed: {
            isExternal() {
                return typeof this.to === 'string' && (this.to && this.to.startsWith('http'));
            }
        }
    })

    export default class AppLink extends Vue {}
</script>
<style lang="scss">
    a:not(.logo-link, .btn) {
        position: relative;
        font-weight: 400;
        text-decoration: none;
        color: var(--Blu2);
        white-space: nowrap;

        .color-section & {
            color: var(--Blu1);
        }

        .color-section.alt-color-section & {
            color: var(--Pur1);
        }

        outline: none;

        transition: font-weight .3s,
        letter-spacing .3s,
        color .3s;

        &[href*=http],
        &[target=_blank] {
            &:after {
                content: "\2197";
                font-size: .75em;
                opacity: 0;
                background: var(--W);
                position: absolute;
                inset: -1px 0px auto auto;
                line-height: 4px;
                height: 9px;
                width: 9.5px;
                text-align: right;
                z-index: 2;

                transition: inset .3s ease, opacity .3s;

                .card & {
                    background: var(--Gry7);
                }

                .color-section & {
                    background: var(--Blu6);
                }

                .color-section.alt-color-section & {
                    background: var(--Pur6);
                }
            }
        }

        &:before {
            pointer-events: none;
            position: absolute;
            content: '';
            inset: -1px 0px;
            border: 0 solid var(--Blu2);
            border-width: 0 0 1.5px;
            border-radius: 0;

            z-index: 1;

            transition: border-width .2s, border-radius .31s ease-in, inset .3s, border-color .2s;

            .color-section.alt-color-section & {
                border-color: var(--Pur2);
            }
        }

        &:hover,
        &:focus,
        &.focus,
        &.router-link-active {
            letter-spacing: -.027em;
            font-weight: 900;

            &:before {
                border-width: 2px;
                border-radius: .5rem;
                inset: -4px -8px;
                transition: border-width .2s, border-radius .2s ease-out, inset .3s, border-color .2s;
            }

            &:after {
                inset: -4px -8.5px auto auto;
                opacity: 1;
            }

            &[href*=http],
            &[target=_blank] {
                &:before {
                    border-radius: .5rem 0 .5rem .5rem;
                }
            }
        }

        &:active,
        &.active {
            color: var(--Pur2);

            &:before {
                inset: 0px -4px;
                border-color: var(--Pur2);
            }
        }

        &.router-link-active {
            color: var(--Pur2);

            &:before {
                inset: -2px -6px;
                border-color: var(--Pur2);
            }
        }
    }
</style>