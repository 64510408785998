<template>
    <footer class="center-text stretch-section">
        Copyright © <span id="curYear">2022</span> Forge Laser Creations & Design<br>
        Developed by: <AppLink to="https://pjm.design" newtab>pjm.design</AppLink>
    </footer>
</template>

<script>
    import {
        Options,
        Vue
    } from 'vue-class-component';
    import AppLink from '@/components/AppLink.vue'
    import $ from 'jquery'

    @Options({
        name: 'Footer',
        components: { AppLink },
        mounted() {
            let y = new Date();
            $('#curYear').html(y.getFullYear());
        },
    })



    export default class PopUp extends Vue {}
</script>

<style lang="scss">
    footer {
        font-size: 80%;
        font-style: italic;
        padding: 2rem;
        color: var(--Gry2);
    }
</style>