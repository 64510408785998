<template>
    <div class="popup" :class="`dir-${dir}`" @mouseout="this.startCloseTimer()" @mouseover="this.stopCloseTimer()">
        <Button class="ghost shrink" @click.stop="this.close()">
            <FeatherIcon :feather-icon="[`chevron-${dir}`]"></FeatherIcon>
        </Button>
        <slot />
    </div>
</template>

<script>
    import {
        Options,
        Vue
    } from 'vue-class-component';
    import Button from './Button.vue'
    import FeatherIcon from './FeatherIcon.vue'
    import $ from 'jquery'

    let closeTimeout;

    @Options({
        name: 'PopUp',
        props: {
            dir: String
        },
        inject: [
            'prefs'
        ],
        components: {
            Button,
            FeatherIcon
        },
        methods: {
            open() {
                this.stopCloseTimer();
                $(this.$el).slideDown({
                    duration: this.prefs.motion?300:0,
                    start: function() {
                        $(this)
                            .css({
                                display: 'flex'
                            })
                            .addClass('popped');
                    }
                })
            },
            close() {
                this.stopCloseTimer();
                $(this.$el).slideUp( {
                    duration: this.prefs.motion?300:0,
                    done: function() {
                        $(this).removeClass('popped');
                    }
                })
            },
            toggle() {
                $(this.$el).hasClass('popped')
                    ? this.close()
                    : this.open();
            },
            startCloseTimer() {
                closeTimeout = setTimeout(() => {
                    this.close();
                }, 500);
            },
            stopCloseTimer() {
                clearTimeout(closeTimeout);
            }
        }
    })



    export default class PopUp extends Vue {}
</script>

<style lang="scss">
    .popup {
        position: absolute;

        display: none;
        flex-direction: column;
        align-items: stretch;
        gap: 0.25rem;
        padding: 0.25rem;

        background-color: rgba(var(--WRGB), 0.6);
        backdrop-filter: blur(24px);
        border: 1px solid var(--Gry8);
        border-radius: 0.75rem;

        z-index: 3;

        transition: background .3s, border .3s;

        // Direction class name says which way the close arrow is pointing, not which way the pop-up opens
        &.dir-down {
            flex-direction: column-reverse;
            inset: auto -.25rem calc(100% + .5rem);
        }

        &.dir-up {
            inset: calc(100% + .5rem) -.25rem auto;
        }
    }
</style>