<template>
    <button :title="title">
        <slot />
    </button>
</template>

<script>
    /* eslint-disable */
    export default {
        name: "Button",
        props: {
            title: String
        }
    }
</script>

<style lang="scss">
    a.btn,
    button:not(.vueperslides__bullet):not(.vueperslides__arrow) {
        display: flex;
        gap: clamp(.25rem, 1vw, .5rem);
        justify-content: center;
        align-items: center;

        background: var(--Blu2);
        color: var(--W);
        cursor: pointer;
        min-height: clamp(32px, 3vw, 42px);
        padding: 0 clamp(.7rem, 3vw, 1.5rem);
        border-radius: clamp(.33rem, 1.25vw, .5rem);
        outline: none;
        box-shadow: 0 0 0 0 var(--Blu2);
        border: 0;
        text-decoration: none;

        user-select: none;

        transition: background .3s, box-shadow .2s, font-weight .3s, color .3s;

        svg.feather {
            stroke: var(--W);
        }

        &:hover,
        &:focus {
            background: var(--Blu1);
            font-weight: 900;
        }

        &:focus-visible {
            box-shadow: 0 0 0 3px var(--Blu2);
        }

        &:focus {
            box-shadow: 0 0 0 3px var(--Blu2);
        }

        &:active,
        &.active {
            color: var(--B);
            background: var(--Blu4);
            box-shadow: 0 0 0 3px var(--Blu2);

            svg.feather {
                stroke: var(--B);
            }
        }

        &.alt-btn {
            background: var(--Pur2);

            &:hover,
            &:focus {
                background: var(--Pur1);
                font-weight: 900;
            }

            &:focus-visible {
                box-shadow: 0 0 0 3px var(--Pur2);
            }

            &:focus {
                box-shadow: 0 0 0 3px var(--Pur2);
            }

            &:active,
            &.active {
                color: var(--B);
                background: var(--Pur4);
                box-shadow: 0 0 0 3px var(--Pur2);
            }
        }

        &.wrap,
        &.ghost {
            padding: clamp(.2rem, .75vw, .33rem);
            min-height: unset;
        }

        &.ghost {
            color: var(--Blu2);
            text-decoration: none;
            background: none;

            svg.feather {
                stroke: var(--Blu2);
            }

            &:hover {
                background-color: var(--Gry7);
            }

            &:focus {
                box-shadow: 0 0 0 3px var(--Blu2);
            }

            &:focus-visible {
                background-color: transparent;
                box-shadow: 0 0 0 3px var(--Blu2);
            }

            &:active,
            &.active {
                color: var(--Pur2);
                background-color: var(--Pur6);
                box-shadow: 0 0 0 3px var(--Pur2);
                svg.feather {
                    stroke: var(--Pur2);
                }
            }

            &.router-link-active {
                color: var(--Pur2);
                background-color: var(--Pur6);
                box-shadow: 0 0 0 0 var(--Pur2);

                svg.feather {
                    stroke: var(--Pur2);
                }

                &:focus,
                &:focus-visible {
                    box-shadow: 0 0 0 3px var(--Pur2);
                }
            }
        }

        &.shrink {
            padding: 0;
        }
    }
</style>