<template>
    <div class="input-container" :class="`${type}-container`" :id="contId">
        <input v-if="['text','email','tel','hidden'].indexOf(type)>=0" :type="type" :name="name" :id="name" :placeholder="label" v-model="val" :class="`${val?'filled':''} ${clearable?'clearable':''}`" :required="required" @keydown="filterInput(inputRegex)"/>
        <textarea v-else-if="type=='textarea'" :name="name" :id="name" :placeholder="label" v-model="val" :class="`${val?'filled':''} ${clearable?'clearable':''}`" required/>
        <label v-if="!nolabel" :class="val&&'show'" :for="name">{{label}}</label>
        <Button v-if="this.val && clearable" @click.prevent="clearInput" :title="`Clear ${type=='textarea'?'Message':'Input'}`" class="input-icon input-clear ghost">
            <FeatherIcon feather-icon="x" />
        </Button>
    </div>
</template>

<script>
    /* eslint-disable */
    import {
        Options,
        Vue
    } from 'vue-class-component'
    import FeatherIcon from './FeatherIcon.vue';
    import Button from './Button.vue';

    let clrInterval;

    @Options({
        name: 'Input',
        props: {
            name: String,
            label: String,
            type: {
                type: String,
                default: 'text'
            },
            inputRegex: {
                type: [String,RegExp],
                default: '.+'
            },
            contId: String,
            pattern: String,
            clearable: Boolean,
            required: Boolean,
            nolabel: {
                type: Boolean,
                default: false
            },
            modelValue: [String,Number]
        },
        components: {
            FeatherIcon,
            Button
        },
        data() {
            return {
                val: this.modelValue
            }
        },
        watch: {
            val(v) {
                this.$emit('update:modelValue', v)
            }
        },
        methods: {
            clearInput() {
                let text = this.val;

                let textLen = text.length,
                    t = (300 / textLen);
                clrInterval = setInterval(() => {
                    textLen < 0
                        ? clearInterval(clrInterval)
                        : (this.val = this.val.slice(0, textLen--));
                }, t);
            },
            filterInput(regex) {
                let e = $(event.target),
                    k = event.key || '';

                let controlKeys = ['Backspace','Escape','ArrowLeft','ArrowRight','Tab','Shift','Control','Meta','Alt','CapsLock','Enter'];

                if (!k.match(regex) && controlKeys.indexOf(k)<0) {
                    event.preventDefault();
                    e.addClass('denied-input');

                    setTimeout(function() {
                        e.removeClass('denied-input')
                    }, 300);
                }
            }
        }
    })

    export default class input extends Vue {}
</script>

<style lang="scss">
    ::-webkit-input-placeholder {
        color: var(--Gry2);
        font-weight: 300;
        transition: color .3s;
    }

    :-ms-input-placeholder {
        color: var(--Gry2);
        font-weight: 300;
        transition: color .3s;
    }

    ::placeholder {
        color: var(--Gry2);
        font-weight: 300;
        transition: color .3s;
    }

    .input-icon {
        position: absolute;
        right: 3.5px;
        top: 3.5px;
        height: calc(100% - 4px);
        display: flex;
        align-items: center;
    }

    .input-clear {
        cursor: pointer;
        height: unset;
    }

    label {
        font-size: 75%;
        font-weight: 400;
        color: var(--B);
        position: absolute;
        left: clamp(.33rem, 1.25vw, .5rem);
        top: 100%;
        opacity: 0;
        transition: top .3s ease, opacity .3s, font-weight .3s, color .3s;

        &.show {
            top: calc(100% + .75em - 9px);
            opacity: .66;
        }
    }

    input, textarea, select, .upload-container {
        font-family: $Font2;
        font-weight: 400;
        color: var(--B);
        background: var(--Gry7);
        outline: none;
        border: 1.5px solid var(--Gry6);
        padding: 0 clamp(.33rem, 1.25vw, .5rem);
        min-height: clamp(32px, 6vw, 42px);
        border-radius: clamp(.33rem, 1.25vw, .5rem);
        box-shadow: 0 0 0 0 var(--Blu2);

        transition: background .3s, box-shadow .3s, color .3s, font-weight .3s, border .3s;

        &:hover {
            background: var(--Gry8);
        }

        &:active,
        &:focus,
        &:focus-within,
        &:focus
        &:focus-visible {
            background: var(--Gry8);
            font-weight: 700;
            border-color: var(--Blu3);
            box-shadow: 0 0 0 3px var(--Blu2);
            svg {
                stroke-width: 2.5px;
            }
            &+label.show {
                opacity: 1;
                font-weight: 900;
            }
        }

        &.filled {
            border-color: var(--Blu4);
            &.clearable {
                padding-right: 35px;
            }
        }

        &.validated:invalid {
            border-color: var(--Red5);
        }

        &.validated:valid {
            border-color: var(--Blu3);
        }

        &.denied-input {
            border-color: var(--Red3);
            box-shadow: 0 0 0 3px var(--Red0);
        }

        &:focus {
            ~.input-icon svg {
                stroke-width: 2.5px;
            }
        }
    }

    input,
    .input-container,
    .select-container {
        flex: 1 0 min(100%, 275px);
    }


    .input-container input,
    select,
    textarea {
        width: 100%;
        height: 100%;
        &.placeholder-text {
            color: var(--Gry2);
            font-weight: 300;
        }
    }

    .input-container,
    .select-container {
        position: relative;
        button, a.btn {
            min-height: clamp(28px, 2.75vw, 38px);
        }
    }

    .textarea-container,
    .upload-container {
        flex: 1 0 100%;
    }

    textarea {
        vertical-align: top;
        resize: vertical;
        min-height: 4rem;
    }

</style>