<template>
    <div v-if="typeof featherIcon === 'array'" ref="featherCont" class="icon-cont multi-icon">
        <i v-for="(icon,index) in featherIcon" :key="index" :data-feather="icon"></i>
        <slot />
    </div>
    <div v-if="typeof featherIcon === 'string'" ref="featherCont" class="icon-cont">
        <i :data-feather="featherIcon"></i>
        <slot />
    </div>
</template>

<script>
    /* eslint-disable */
    import feather from 'feather-icons'
    import $ from 'jquery'

    export default {
        props: {
            featherIcon: [String, Array],
            iconLabel: String,
            // addClass: String
        },
        mounted() {
            if (this.featherIcon.length) feather.replace();
        },
        watch: {
            featherIcon() {
                feather.icons[this.featherIcon] && $(this.$refs.featherCont).html(feather.icons[this.featherIcon].toSvg());
            }
        },
    }
</script>

<style lang="scss">
    .icon-cont {
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: stretch;
    }

    .feather {
        stroke-width: 1.5px;
        transition: fill .3s, stroke .3s, stroke-width .3s;
        max-width: clamp(18px,3.5vw,24px);
        max-height: clamp(18px,3.5vw,24px);
    }


    .btn,
    button {
        .feather {
            stroke: var(--Blu2);
        }

        &:hover,
        &:focus,
        &:focus-visible {
            .feather {
                stroke-width: 2.25px;
            }
        }

        &:active,
        &.active,
        &.router-link-active {
            .feather {
                stroke: var(--Pur2);
            }
        }
    }
</style>