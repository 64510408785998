<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 989.324 395.58" alt="Forge Laser Logo" title="Forge Laser Logo">
        <title>Forge Laser Logo</title>
        <linearGradient id="BluPurGL">
            <stop class="stop0" offset=".25" />
            <stop class="stop1" offset=".4" />
            <stop class="stop2" offset=".6" />
        </linearGradient>
        <mask id="frgLogMsk" fill="white">
            <path d="M192.042,196.69l-55.18,23.42H79.682a8.2,8.2,0,0,0-7.92,6.08l-18.6,69.42a8.208,8.208,0,0,1-7.92,6.08H8.212a8.21,8.21,0,0,1-7.93-10.33L51.572,99.97a8.2,8.2,0,0,1,7.92-6.08h113.35a8.2,8.2,0,0,1,7.92,10.32l-7.86,29.35a8.208,8.208,0,0,1-7.92,6.08h-63.73a8.208,8.208,0,0,0-7.93,6.08l-4.9,18.31a8.2,8.2,0,0,0,7.92,10.33h54.83Z" />
            <path d="M281.092,235.28l21.64,75.58-38.18-60.16c-3.655-5.723-14.205-2.529-16.78,4.75l-49.43,140.13L225.4,255.25c1.425-7.386-7.488-10.468-14.15-4.85l-67.19,56.62,59.36-71.85c5.173-6.263,1.274-13.438-6.3-12.04l-71.8,13.02L198,205.3c7.28-3.08,9.61-11.78,3.99-14.86l-57.73-31.54,66.43,13.43c6.928,1.409,14.578-5.824,12.8-12.03l-21.66-75.57,38.21,60.16c3.6,5.687,14.173,2.582,16.77-4.76L306.222,0l-27.04,140.36c-1.427,7.4,7.522,10.439,14.15,4.85l67.23-56.63-59.38,71.84c-5.026,6.087-1.552,13.368,6.3,12.04l71.8-13L306.6,190.28c-7.28,3.09-9.62,11.79-3.99,14.86l57.71,31.57-66.43-13.45C287.05,221.845,279.312,229.04,281.092,235.28Z" />
            <polygon points="393.932 174.36 381.672 220.12 351.362 220.12 312.552 198.9 370.422 174.36 393.932 174.36" />
            <path d="M578.632,174.36a160.969,160.969,0,0,0-11.5,45.76h-54.37c-1.474-3.145,6.433,13.726-23.18-49.47a29.641,29.641,0,0,0,16.07-19.74q3.15-11.685-3.25-17.93c-7.91-7.72-23.3-6.03-25.42-6.24a7.385,7.385,0,0,0-7.12,5.46v.01l-3.5,13.05,68.3,145.75a7.373,7.373,0,0,1-6.6,10.68h-38.97a7.369,7.369,0,0,1-6.91-4.78L446.3,220.12l-20.23,75.49a8.2,8.2,0,0,1-7.92,6.08h-37.03a8.21,8.21,0,0,1-7.93-10.33l51.29-191.39a8.208,8.208,0,0,1,7.92-6.08h77.77c45.407,0,67,29.207,46.06,80.47Z" />
            <path d="M829.092,174.36l-12.27,45.76h-26.51c-3.33,9-4.57,12.67-9.39,20.38a143.863,143.863,0,0,1-23.43,28.8,135.056,135.056,0,0,1-28.9,20.95,144.81,144.81,0,0,1-32.78,12.81,139.327,139.327,0,0,1-34.8,4.41q-24.255,0-42.1-7.99t-28.29-22.46q-10.455-14.475-13.02-34.72-2.565-20.265,4.01-44.79A152.845,152.845,0,0,1,601.392,153a142.019,142.019,0,0,1,72.44-56.91,141.546,141.546,0,0,1,47.61-7.99q25.035,0,43.1,8.33a8.21,8.21,0,0,1,2.25,13.37L737.6,137.9a8.317,8.317,0,0,1-9.64,1.28,40.554,40.554,0,0,0-19.07-4.23,61.026,61.026,0,0,0-23.27,4.55,69.283,69.283,0,0,0-20.42,12.95,89.865,89.865,0,0,0-16.45,20.26,101.607,101.607,0,0,0-11.09,26.46,83.144,83.144,0,0,0-3.12,24.52,42.218,42.218,0,0,0,5.09,19.57,31.664,31.664,0,0,0,13.65,12.81q8.97,4.56,22.48,4.55a71.63,71.63,0,0,0,15.92-1.79,53.464,53.464,0,0,0,14.91-5.79,49.462,49.462,0,0,0,12.56-10.33,76.937,76.937,0,0,0,13.62-22.59h-88.02c.276-12.957,5.222-31.063,15.1-45.76Z" />
            <path d="M989.042,104.21l-7.87,29.35a8.2,8.2,0,0,1-7.92,6.08h-63.93a8.2,8.2,0,0,0-7.92,6.08l-26.77,99.89a8.2,8.2,0,0,0,7.92,10.33h55.14a8.2,8.2,0,0,1,7.92,10.33l-7.86,29.33a8.2,8.2,0,0,1-7.92,6.08H816.272a8.2,8.2,0,0,1-7.92-10.32l51.28-191.39a8.2,8.2,0,0,1,7.93-6.08h113.55A8.2,8.2,0,0,1,989.042,104.21Z" />
            <polygon points="970.232 174.36 957.982 220.12 892.082 220.12 904.342 174.36 970.232 174.36" />
        </mask>
        <g mask="url(#frgLogMsk)">
            <rect x="0" y="0" width="989.324" height="395.58" fill="url(#BluPurGL)" />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'SiteLogo'
    }
</script>

<style lang="scss">
    .stop0 {
        stop-color: var(--Blu2);
        transition: stop-color .3s;
    }
    .stop1 {
        stop-color: var(--BluPurGL-Mid);
        transition: stop-color .3s;
    }
    .stop2 {
        stop-color: var(--Pur3);
        transition: stop-color .3s;
    }
    svg {
        pointer-events: none;
    }
</style>